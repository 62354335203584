import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import IosShare from "../assets/iOSshare.inline.svg"
import IosAddToHome from "../assets/iOSaddToHomeScreen.inline.svg"
import { GlobalContext } from "../components/soundToggle"
import * as Styles from "../styles/sidebar.module.css"

export const InstallPWATimeline = () => {
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const installPromptEvent = useSoundEnabled.installPromptEvent
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const [deferredPrompt, setDeferredPrompt] = useState(undefined)

  const isIOS = () => {
    // @ts-ignore
    if (typeof window !== `undefined`) {
      if (navigator.standalone) {
        //user has already installed the app
        return false
      }
    }
    const ua =
      typeof window !== `undefined`
        ? window.navigator.userAgent
        : "thisIsServerSide"
    const isIPad = !!ua.match(/iPad/i)
    const isIPhone = !!ua.match(/iPhone/i)
    return isIPad || isIPhone
  }

  useEffect(() => {
    if (isIOS() === true) {
      document.querySelector('[class*="installPWATimeline"]').style.display =
        "block"
      return
    }
    if (installPromptEvent !== undefined) {
      //const beforeInstallPromptHandler = beforeInstallPromptEvent => {
      if (deferredPrompt === null) {
        return
      }
      document.querySelector('[class*="installPWATimeline"]').style.display =
        "block"
      setDeferredPrompt(installPromptEvent)
      const installButton = document.querySelector(".installButton")
      //attach installprompt to install button
      installButton.addEventListener("click", function (mouseEvent) {
        play()
        const promptEvent = installPromptEvent
        if (!promptEvent) {
          document.querySelector(
            '[class*="installPWATimeline"]'
          ).style.display = "none"
          // The deferred prompt isn't available.
          return
        }
        // Show the install prompt.
        installPromptEvent.prompt()
        // Log the result
        promptEvent.userChoice.then(result => {
          setDeferredPrompt(null)
          // Reset the deferred prompt variable, since
          // prompt() can only be called once.
          // Hide the install button.
          document.querySelector(
            '[class*="installPWATimeline"]'
          ).style.display = "none"
        })
      })
    }
  }, [installPromptEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={Styles.installPWATimeline}
      style={{
        display: "none",
        padding: isIOS() ? "8px 8px 16px" : "8px 8px 0",
      }}
    >
      <h3>{t("TIMELINE_PWA_INSTALLATION_HEADLINE")}</h3>
      {isIOS() === false && <p>{t("TIMELINE_PWA_INSTALLATION_FLOW")}</p>}
      {isIOS() === true && (
        <span>
          <p>{t("IOS_TIMELINE_PWA_INSTALLATION_ONE")}</p>
          <IosShare
            height="16"
            className="iOSicon"
            style={{ margin: "0 4px", filter: "none" }}
          />
        </span>
      )}
      {isIOS() === true && (
        <span>
          <p>{t("IOS_TIMELINE_PWA_INSTALLATION_TWO")}</p>
          <IosAddToHome
            height="14"
            className="iOSicon"
            style={{
              margin: "0",
              filter: "none",
              transform: "translateY(2px)",
            }}
          />
        </span>
      )}
      {isIOS() === false && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            transform: "translateY(-12px)",
            zIndex: "11",
            position: "relative",
          }}
        >
          <button className="installButton" style={{ fontSize: "16px" }}>
            {t("INSTALL_TIMELINE_PWA_BUTTON")}
          </button>
        </div>
      )}
    </div>
  )
}

export const InstallPWA = () => {
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const installPromptEvent = useSoundEnabled.installPromptEvent
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const [deferredPrompt, setDeferredPrompt] = useState(undefined)

  const isIOS = () => {
    // @ts-ignore
    if (typeof window !== `undefined`) {
      if (navigator.standalone) {
        //user has already installed the app
        return false
      }
    }
    const ua =
      typeof window !== `undefined`
        ? window.navigator.userAgent
        : "thisIsServerSide"
    const isIPad = !!ua.match(/iPad/i)
    const isIPhone = !!ua.match(/iPhone/i)
    return isIPad || isIPhone
  }

  useEffect(() => {
    if (isIOS() === true) {
      document.querySelector(".installPWA").style.display = "block"
      return
    }

    if (installPromptEvent !== undefined) {
      //const beforeInstallPromptHandler = beforeInstallPromptEvent => {
      if (deferredPrompt === null) {
        return
      }
      document.querySelector(".installPWA").style.display = "block"
      setDeferredPrompt(installPromptEvent)
      const installButton = document.querySelector(".installButton")
      //attach installprompt to install button
      installButton.addEventListener("click", function () {
        play()
        const promptEvent = installPromptEvent
        if (!promptEvent) {
          document.querySelector(".installPWA").style.display = "none"
          // The deferred prompt isn't available.
          return
        }
        // Show the install prompt.
        installPromptEvent.prompt()
        // Log the result
        promptEvent.userChoice.then(() => {
          setDeferredPrompt(null)
          // Reset the deferred prompt variable, since
          // prompt() can only be called once.
          // Hide the install button.
          document.querySelector(".installPWA").style.display = "none"
        })
      })
    }
  }, [installPromptEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="installPWA" style={{ display: "none" }}>
      <h2 style={{ marginTop: "1em" }}>{t("TRY_PWA_INSTALLATION_HEADLINE")}</h2>
      {isIOS() === false && <p>{t("TRY_PWA_INSTALLATION_FLOW")}</p>}
      {isIOS() === true && (
        <span>
          <p>{t("IOS_PWA_INSTALLATION_ONE")}</p>
          <IosShare
            className="iOSicon"
            style={{ margin: "0px 8px", filter: "none" }}
          />
        </span>
      )}
      {isIOS() === true && (
        <span>
          <p>{t("IOS_PWA_INSTALLATION_TWO")}</p>
          <IosAddToHome
            height="20px"
            width="20px"
            className="iOSicon"
            style={{
              margin: "0px 8px",
              filter: "none",
              transform: "translateY(3px)",
            }}
          />
        </span>
      )}
      {isIOS() === false && (
        <button className="installButton">{t("INSTALL_PWA_BUTTON")}</button>
      )}
    </div>
  )
}
