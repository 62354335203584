import { gsap } from "gsap"

export function floating(blob1, blob2, blob3, blob4, pageNum, dot1, dot2) {
  const randomX = random(10, 20)
  const randomY = random(20, 30)
  const randomTime = random(5, 8)
  const randomTime2 = random(5, 10)
  const randomAngle = random(8, 12)

  gsap.set(blob1, {
    x: randomX(-1),
    y: randomX(1),
    rotation: randomAngle(-1),
  })

  gsap.set(blob2, {
    x: randomX(-1),
    y: randomX(1),
    rotation: randomAngle(-1),
  })

  gsap.set(blob4, {
    x: randomX(-1),
    y: randomX(1),
    rotation: randomAngle(-1),
  })

  moveX(blob1, 1)
  moveX(blob2, 1)
  moveX(blob3, 1)
  moveX(blob4, 1)
  moveX(pageNum, 1)
  moveX(dot1, 1)
  moveX(dot2, 1)
  moveY(blob1, 1)
  moveY(blob2, 1)
  moveY(blob3, 1)
  moveY(blob4, 1)
  moveY(pageNum, 1)
  moveY(dot1, 1)
  moveY(dot2, 1)
  rotate(blob1, 1)
  rotate(blob2, 1)
  rotate(blob4, 1)

  function rotate(target, direction) {
    gsap.to(target, randomTime2(), {
      rotation: randomAngle(direction),
      // delay: randomDelay(),
      ease: "sine.out",
      onComplete: rotate,
      onCompleteParams: [target, direction * -1],
    })
  }

  function moveX(target, direction) {
    gsap.to(target, randomTime(), {
      x: randomX(direction),
      ease: "sine.out",
      onComplete: moveX,
      onCompleteParams: [target, direction * -1],
    })
  }

  function moveY(target, direction) {
    gsap.to(target, randomTime(), {
      y: randomY(direction),
      ease: "sine.out",
      onComplete: moveY,
      onCompleteParams: [target, direction * -1],
    })
  }

  function random(min, max) {
    const delta = max - min
    return (direction = 1) => (min + delta * Math.random()) * direction
  }
}

export function fadeIn(element) {
  const tl = gsap.timeline({
    repeat: 0,
    immediateRender: true,
    delay: 0,
    autoRemoveChildren: true,
  })
  tl.to(element, { duration: 0.4, autoAlpha: 1 })
}

export function GSAPfadeout(element1) {
  const tl = gsap.timeline({
    repeat: 0,
    immediateRender: true,
    delay: 0,
    autoRemoveChildren: true,
  })
  tl.to(element1, { duration: 0.4, autoAlpha: 0 })
}

export function floating404(blob1, pageNum, dot1) {
  const randomX = random(10, 20)
  const randomY = random(20, 30)
  const randomTime = random(5, 8)
  const randomTime2 = random(5, 10)
  const randomAngle = random(8, 12)

  gsap.set(blob1, {
    x: randomX(-1),
    y: randomX(1),
    rotation: randomAngle(-1),
  })

  moveX(blob1, 1)
  moveX(pageNum, 1)
  moveX(dot1, 1)
  moveY(blob1, 1)
  moveY(pageNum, 1)
  moveY(dot1, 1)
  rotate(blob1, 1)

  function rotate(target, direction) {
    gsap.to(target, randomTime2(), {
      rotation: randomAngle(direction),
      ease: "sine.out",
      onComplete: rotate,
      onCompleteParams: [target, direction * -1],
    })
  }

  function moveX(target, direction) {
    gsap.to(target, randomTime(), {
      x: randomX(direction),
      ease: "sine.out",
      onComplete: moveX,
      onCompleteParams: [target, direction * -1],
    })
  }

  function moveY(target, direction) {
    gsap.to(target, randomTime(), {
      y: randomY(direction),
      ease: "sine.out",
      onComplete: moveY,
      onCompleteParams: [target, direction * -1],
    })
  }

  function random(min, max) {
    const delta = max - min
    return (direction = 1) => (min + delta * Math.random()) * direction
  }
}

export function swipeDown(hand, arrow, swipeDownAnim) {
  const fade = () => {
    const tl2 = gsap.timeline({ repeat: 0 })
    tl2.to(hand, {
      duration: 1.2,
      ease: "circ.out",
      rotate: 20,
      transformOrigin: "right bottom",
    })
    tl2.to(
      hand,
      {
        duration: 0.8,
        ease: "power2.out",
        rotate: 0,
        transformOrigin: "right bottom",
      },
      ">"
    )
    tl2.to(arrow, { duration: 0.5, y: 40, yoyo: true, repeat: 3 }, "0")
    tl2.to([hand, arrow], { duration: 0.6, autoAlpha: 0 }, "0")
    tl2.set(swipeDownAnim, { display: "none" }, ">")
  }

  gsap.set(swipeDownAnim, { display: "block" })
  gsap.to([hand, arrow], { duration: 0.6, autoAlpha: 1, delay: 5 })
  const tl = gsap.timeline({ repeat: 3, delay: 5, onComplete: fade })
  tl.to(hand, {
    duration: 1.2,
    ease: "circ.out",
    rotate: 20,
    transformOrigin: "right bottom",
  })
  tl.to(
    hand,
    {
      duration: 0.8,
      ease: "power2.out",
      rotate: 0,
      transformOrigin: "right bottom",
    },
    ">"
  )
  tl.to(arrow, { duration: 0.5, y: 40, yoyo: true, repeat: 3 }, "0")
}

export function scrollDownMouse(mouse, scrollWheel, arrow, ScrollDownAnim) {
  const fade = () => {
    const tl2 = gsap.timeline({ repeat: 0 })
    tl2.to(scrollWheel, {
      duration: 1,
      yoyo: true,
      repeat: 1,
      ease: "power2.inOut",
      y: "+=46",
    })
    tl2.to(arrow, { duration: 0.5, y: 40, yoyo: true, repeat: 3 }, "0")
    tl2.to([scrollWheel, mouse, arrow], { duration: 0.6, autoAlpha: 0 }, "0")
    tl2.set(ScrollDownAnim, { display: "none" }, ">")
  }

  gsap.set(ScrollDownAnim, { display: "block" })
  gsap.to([scrollWheel, mouse, arrow], {
    duration: 0.6,
    autoAlpha: 1,
    delay: 5,
  })
  const tl = gsap.timeline({ repeat: 3, delay: 5, onComplete: fade })
  tl.to(scrollWheel, {
    duration: 1,
    yoyo: true,
    repeat: 1,
    ease: "power2.inOut",
    y: "+=46",
  })
  tl.to(arrow, { duration: 0.5, y: 40, yoyo: true, repeat: 3 }, "0")
}

export function pushButton(hand, circle1, circle2, pushButtonAnim) {
  const tl = gsap.timeline({ repeat: 2, repeatDelay: 1, delay: 15 })
  tl.set(pushButtonAnim, { display: "block" })
  tl.set(hand, { y: -20 })
  tl.set(circle2, { x: -75 })
  tl.to(hand, { duration: 0.9, autoAlpha: 1 }, "0")
  tl.to(hand, { duration: 1, ease: "power2.inOut", y: "+=20" }, "<+0.6")
  tl.fromTo(
    circle1,
    {
      transformOrigin: "center center",
      duration: 0.5,
      scale: 0.8,
      autoAlpha: 0,
    },
    { scale: 1, autoAlpha: 1 },
    "<+0.4"
  )
  tl.to(
    hand,
    { duration: 2, ease: "power2.inOut", y: "-=20", x: "+=135" },
    ">+0.4"
  )
  tl.to(
    circle1,
    {
      transformOrigin: "center center",
      duration: 0.5,
      scale: 0.8,
      autoAlpha: 0,
    },
    "<+0.2"
  )
  tl.to(hand, { duration: 1, ease: "power2.inOut", y: "+=20" }, ">+1.3")
  tl.fromTo(
    circle2,
    {
      transformOrigin: "center center",
      duration: 0.5,
      scale: 0.8,
      autoAlpha: 0,
    },
    { scale: 1, autoAlpha: 1 },
    "<+0.4"
  )
  tl.to(hand, { duration: 0.5, ease: "power2.inOut", y: "-=20" }, ">+0.4")
  tl.to(
    circle2,
    {
      transformOrigin: "center center",
      duration: 0.5,
      scale: 0.8,
      autoAlpha: 0,
    },
    "<"
  )
  tl.to(hand, { duration: 0.9, autoAlpha: 0 }, "<+0.3")
  tl.set(pushButtonAnim, { display: "none" }, ">")
  tl.timeScale(1.5)
}
