//nyeste nyheder tilføjes øverst i JSON
export const timelineData = [
  // {
  //   image: "PWA_perspective_closeup",
  //   image_alt_da: "Fordele ved en PWA",
  //   image_alt_en: "Advantages of a PWA",
  //   url_da:
  //     "/artikler/derfor-bor-din-naeste-hjemmeside-vaere-en-progressive-web-app/",
  //   url_en:
  //     "/en/artikler/derfor-bor-din-naeste-hjemmeside-vaere-en-progressive-web-app/",
  //   headline_da: "Bliv klogere på Progressive Web Apps",
  //   headline_en: "Learn About Progressive Web Apps",
  //   body_da:
  //     "Progressive Web Apps har ikke opnået den store udbredelse endnu. Jeg ser nærmere på om det bliver stort i fremtiden.",
  //   body_en:
  //     "Progressive Web Apps aren't widely adopted yet. What are the future possibilities of PWAs becoming a success?",
  //   date_da: "20. februar 2021",
  //   date_en: "February 20th, 2021",
  //   outline: "outline4",
  // },
  {
    image: "AccuRanker_timeline",
    image_alt_da: "AccuRanker logo på turkis baggrund",
    image_alt_en: "AccuRanker logo on top of a turquoise background",
    url_da: "/",
    url_en: "/",
    headline_da: "Nyt job hos AccuRanker",
    headline_en: "New job at AccuRanker",
    body_da:
      "1. maj starter jeg ud i et nyt spændende job som frontend-udvikler hos AccuRanker",
    body_en:
      "I am excited to be joining AccuRanker on May 1st as a frontend developer.",
    date_da: "29. april 2022",
    date_en: "April 29th, 2022",
    outline: "outline3",
  },
  {
    image: "updated_skills",
    image_class: "noFilter",
    image_alt_da: "Opdateret kompetenceprofil",
    image_alt_en: "Updated list of skills",
    url_da: "/kompetencer/",
    url_en: "/en/kompetencer/",
    headline_da: "Tjek min opdaterede kompetence profil",
    headline_en: "Check out my Updated list of Skills",
    body_da:
      "Her får du et overblik over mine evner indenfor webudvikling og -design.",
    body_en:
      "Here you get an overview of my abilities in web development and -design.",
    date_da: "17. februar 2022",
    date_en: "February 17th, 2022",
    outline: "outline1",
  },
  {
    image: "Twitter_timeline",
    image_alt_da: "Følg mig på Twitter",
    image_alt_en: "Follow me on Twitter",
    url_da:
      "https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5ELarsEjaas&screen_name=LarsEjaas",
    url_en:
      "https://twitter.com/intent/follow?original_referer=https%3A%2F%2Fpublish.twitter.com%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5ELarsEjaas&screen_name=LarsEjaas",
    headline_da: "Følg mig på Twitter",
    headline_en: "Follow me on Twitter",
    body_da:
      "Her skriver jeg om min passion for programmering, teknologi og mit arbejde som udvikler.",
    body_en:
      "I tweet about the stuff I am passionated about: Programming, technology and my life as a developer.",
    date_da: "17. februar 2022",
    date_en: "February 17th, 2022",
    outline: "outline4",
  },
  {
    image: "SallingGroup",
    image_alt_da: "Salling Group logo",
    image_alt_en: "Salling Group logo",
    url_da: "/",
    url_en: "/en/",
    headline_da: "Frontend udvikler hos Salling Group",
    headline_en: "Frontend Developer at Salling Group",
    body_da:
      "Jeg kan nu se tilbage på de første 4 måneders ansættelse hos Salling Group.\nDet har været en meget lærerig process indtil videre og jeg er stolt af at være en del af et dygtigt team.",
    body_en:
      "Now looking back on the first 4 months as an employee at Salling Group.\nIt's been a great learning experience so far, and I am excited to be part of a talented team.",
    date_da: "10. februar 2022",
    date_en: "February 10th, 2022",
    outline: "outlineGold",
  },
  {
    image: "bruce_willis_rocks",
    image_class: "noFilter",
    image_alt_da: "Screenshot fra siden bruce-willis.rocks",
    image_alt_en: "Screenshot from bruce-willis.rocks",
    special: "anchor",
    url_da: "/portfolio/#brucewillis/",
    url_en: "/en/portfolio/#brucewillis/",
    headline_da: "En hyldest til Bruce Willis",
    headline_en: "A Tribute to Bruce Willis",
    body_da:
      "Læs mere om mit seneste projekt, der hylder en af Hollywoods største filmstjerner.",
    body_en:
      "Read about my latest project, celebrating one of Hollywood's greatest movie stars.",
    date_da: "13. maj 2021",
    date_en: "May 13th, 2021",
    outline: "outline3",
  },
  {
    image: "lars_ejaas_com_timeline",
    image_alt_da: "Screenshot mosaik fra larsejaas.com",
    image_alt_en: "Screenshot mosaic from larsejaas.com",
    special: "anchor",
    url_da: "/portfolio/#mosaic/",
    url_en: "/en/portfolio/#mosaic/",
    headline_da: "Om LarsEjaas.com",
    headline_en: "About LarsEjaas.com",
    body_da: "Læs mere om udviklingen af denne hjemmeside.",
    body_en: "Read more about how I made this page.",
    date_da: "11. maj 2021",
    date_en: "May 11th, 2021",
    outline: "outline2",
  },
  {
    image: "hand_holding_letter",
    image_alt_da: "Hånd der holder et brev",
    image_alt_en: "Hand holding a letter",
    special: "skrivTilMig",
    url_da: "/",
    url_en: "/",
    headline_da: "Kontakt mig direkte",
    headline_en: "Write to me Directly",
    body_da: "Du kan skrive en besked til mig direkte her fra siden.",
    body_en: "You can write a message to me directly from this webpage.",
    date_da: "1. marts 2021",
    date_en: "March 1st, 2021",
    outline: "outline4",
  },
  {
    image: "Skills_timeline",
    image_alt_da: "Visuel oversigt over kompetencer for lars ejaas",
    image_alt_en: "Visual overview of skills for lars ejaas",
    url_da: "/kompetencer/",
    url_en: "/en/kompetencer/",
    headline_da: "Få et overblik over mine kompetencer",
    headline_en: "Get an Overview of my Development Skills",
    body_da:
      "Her får du et overblik over mine evner indenfor webudvikling og -design.",
    body_en:
      "Here you get an overview of my abilities in web development and -design.",
    date_da: "1. marts 2021",
    date_en: "March 1st, 2021",
    outline: "outline2",
  },
  {
    image: "ejaas_logo",
    image_alt_da: "ejaas logo",
    image_alt_en: "ejaas logo",
    url_da: "/",
    url_en: "/en/",
    headline_da: "Velkommen til min nye hjemmeside",
    headline_en: "Welcome to my New Website",
    body_da:
      "Her i sidebjælken kan du løbende følge med i opdateringer på siden.",
    body_en:
      "In this sidebar you can keep up to date with any updates on the page.",
    date_da: "28. februar 2021",
    date_en: "February 28th, 2021",
    outline: "outline1",
  },
]
