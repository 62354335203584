import React, { useState, createContext, useContext, useEffect } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import ToggleSound from "../sounds/toggle-button.mp3"
import VolumeUp from "../assets/volume-up.inline.svg"
import VolumeMute from "../assets/volume-mute.inline.svg"

const root =
  typeof window !== `undefined` ? window.document.documentElement : undefined
const soundEnabledInitial =
  typeof window !== `undefined`
    ? JSON.parse(root.getAttribute("data-sound"))
    : true

export const GlobalContext = createContext({
  soundEnabledStatus: soundEnabledInitial,
  soundToggle: () => {},
})

const SoundButton = () => {
  const [isClient, setClient] = useState(false)
  const key1 = isClient ? `1` : `2`
  const key2 = isClient ? `3` : `4`
  const [count, setCount] = useState(0)
  const { soundEnabledStatus, soundToggle } = useContext(GlobalContext)
  const [play] = useSound(ToggleSound, {
    volume: 0.25,
    soundEnabled: GlobalContext,
  })
  const { t } = useTranslation()
  let muted
  if (typeof window !== undefined) {
    muted = !soundEnabledStatus
  } else {
    muted = !soundEnabledInitial
  }

  useEffect(() => {
    setClient(true)
    if (count !== 0) {
      play()
    }
    setCount(prevCount => prevCount + 1)
  }, [soundEnabledStatus]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="soundToggle">
      <button
        title={t("TOGGLE_SOUND")}
        aria-pressed={muted}
        aria-label={t("MUTE_SOUND")}
        onClick={soundToggle}
        className={
          muted ? `${"soundButton " + muted}` : `${"soundButton " + muted}`
        }
      >
        <VolumeMute
          key={key1}
          className={muted ? "VolumeMute visible" : "VolumeMute hidden"}
        />
        <VolumeUp
          key={key2}
          className={muted ? "VolumeMute hidden" : "VolumeMute visible"}
        />
      </button>
    </div>
  )
}

export default SoundButton
