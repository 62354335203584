import React, { useState, useContext, useEffect } from "react"
import FooterWave from "../assets/footer_wave2.inline.svg"
import PushButton from "../assets/push_button_anim.inline.svg"
import Logo from "../assets/ejaasLogo.inline.svg"
import { ListTLink, transitionFadeOut, transitionFadeIn } from "./layout"
import ContactForm from "../components/contactForm"
import ShareModal from "../components/shareModal"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import { useScrollPosition } from "../components/useScrollPosition"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { GlobalContext } from "../components/soundToggle"
import { pushButton } from "../components/GSAPanimations"
import useDeviceDetect from "../components/deviceDetect"

const Footer = () => {
  const { isMobile } = useDeviceDetect()
  const storageVal =
    typeof window !== `undefined`
      ? window.localStorage.getItem("pushButton")
      : "0"
  const [pushButtonStorage, setPushButtonStorage] = useState(
    JSON.parse(storageVal)
  )
  const { language } = useI18next()
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  const [hidden, setHidden] = useState(false)
  var throttleTimeout

  const pushUp = () => {
    play()
    if (typeof window !== `undefined`) {
      if (pushButtonStorage < 20) {
        setPushButtonStorage(pushButtonStorage + 1)
      }
      window.localStorage.setItem(
        "pushButton",
        JSON.stringify(pushButtonStorage)
      )
    }
  }

  useEffect(() => {
    const hand2 = document.querySelector("#hand2")
    const circle1 = document.querySelector("#circle1")
    const circle2 = document.querySelector("#circle2")
    const pushButtonAnim = document.querySelector("#pushButtonAnim")
    if (pushButtonStorage < 3) {
      pushButton(hand2, circle1, circle2, pushButtonAnim)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useScrollPosition(
    ({ prevPos, currPos, wait }) => {
      const isShow = currPos.y > prevPos.y
      //if (isTouchDevice() === true) {
      if (isShow !== hidden) {
        setHidden(true)
        throttleTimeout = setTimeout(callBack, 400)
      }
      // }
    },
    [hidden]
  )

  //=====
  clearInterval(throttleTimeout)

  const callBack = () => {
    setHidden(false)
    throttleTimeout = null
  }

  //=====
  let index, portfolio, webdevelopment, graphics, skills, articles

  if (language === "da") {
    index = "/"
    portfolio = "/portfolio/"
    webdevelopment = "/webudvikling/"
    graphics = "/grafik/"
    skills = "/kompetencer/"
    articles = "/artikler/"
  } else {
    index = `/${language}/`
    portfolio = `/${language}/portfolio/`
    webdevelopment = `/${language}/webudvikling/`
    graphics = `/${language}/grafik/`
    skills = `/${language}/kompetencer/`
    articles = `/${language}/artikler/`
  }

  return (
    <>
      <PushButton />
      <footer
        className="footer"
        style={{
          position: "sticky",
          transform: hidden && isMobile ? "translateY(100%)" : "translateY(0)",
          transition: "transform 300ms cubic-bezier(1, 0, 1, 1)",
          left: 0,
        }}
      >
        <div className="footer-container">
          <div style={{ display: "inline-flex" }}>
            <Logo className="logo" />
            <ShareModal />
            <ContactForm />
          </div>
          <nav className="pagination" aria-label="Main">
            <div className="dots">
              <ListTLink
                to={index}
                exit={{
                  length: 0.4,
                  trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
                }}
                entry={{
                  delay: 0.4,
                  appearAfter: 0.4,
                  length: 0.4,
                  trigger: ({ entry, node }) => transitionFadeIn(node),
                }}
                label={t("NAVIGATE_TO_INDEX")}
              >
                <button
                  onClick={pushUp}
                  aria-label="Navigation Button"
                  title={t("NAVIGATE_TO_INDEX")}
                  tabindex="-1"
                />
              </ListTLink>
              <ListTLink
                to={portfolio}
                exit={{
                  length: 0.4,
                  trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
                }}
                entry={{
                  delay: 0.4,
                  appearAfter: 0.4,
                  length: 0.4,
                  trigger: ({ entry, node }) => transitionFadeIn(node),
                }}
                label={t("NAVIGATE_TO_PORTFOLIO")}
              >
                <button
                  onClick={pushUp}
                  aria-label="Navigation Button"
                  title={t("NAVIGATE_TO_PORTFOLIO")}
                  tabindex="-1"
                />
              </ListTLink>
              <ListTLink
                to={webdevelopment}
                exit={{
                  length: 0.4,
                  trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
                }}
                entry={{
                  delay: 0.4,
                  appearAfter: 0.4,
                  length: 0.4,
                  trigger: ({ entry, node }) => transitionFadeIn(node),
                }}
                label={t("NAVIGATE_TO_WEBDEVELOPMENT")}
              >
                <button
                  onClick={pushUp}
                  aria-label="Navigation Button"
                  title={t("NAVIGATE_TO_WEBDEVELOPMENT")}
                  tabindex="-1"
                />
              </ListTLink>
              <ListTLink
                to={graphics}
                exit={{
                  length: 0.4,
                  trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
                }}
                entry={{
                  delay: 0.4,
                  appearAfter: 0.4,
                  length: 0.4,
                  trigger: ({ entry, node }) => transitionFadeIn(node),
                }}
                label={t("NAVIGATE_TO_GRAPHICS")}
              >
                <button
                  onClick={pushUp}
                  aria-label="Navigation Button"
                  title={t("NAVIGATE_TO_GRAPHICS")}
                  tabindex="-1"
                />
              </ListTLink>
              <ListTLink
                to={skills}
                exit={{
                  length: 0.4,
                  trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
                }}
                entry={{
                  delay: 0.4,
                  appearAfter: 0.4,
                  length: 0.4,
                  trigger: ({ entry, node }) => transitionFadeIn(node),
                }}
                label={t("NAVIGATE_TO_SKILLS")}
              >
                <button
                  onClick={pushUp}
                  aria-label="Navigation Button"
                  title={t("NAVIGATE_TO_SKILLS")}
                  tabindex="-1"
                />
              </ListTLink>
              <ListTLink
                to={articles}
                exit={{
                  length: 0.4,
                  trigger: ({ exit, e, node }) => transitionFadeOut(exit, node),
                }}
                entry={{
                  delay: 0.4,
                  appearAfter: 0.4,
                  length: 0.4,
                  trigger: ({ entry, node }) => transitionFadeIn(node),
                }}
                label={t("NAVIGATE_TO_ARTICLES")}
              >
                <button
                  onClick={pushUp}
                  aria-label="Navigation Button"
                  title={t("NAVIGATE_TO_ARTICLES")}
                  tabindex="-1"
                />
              </ListTLink>
            </div>
          </nav>
        </div>
        <FooterWave className="footerWave" preserveAspectRatio="none" />
      </footer>
    </>
  )
}

export default Footer
