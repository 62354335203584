exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikler-js": () => import("./../../../src/pages/artikler.js" /* webpackChunkName: "component---src-pages-artikler-js" */),
  "component---src-pages-besked-modtaget-js": () => import("./../../../src/pages/besked-modtaget.js" /* webpackChunkName: "component---src-pages-besked-modtaget-js" */),
  "component---src-pages-grafik-js": () => import("./../../../src/pages/grafik.js" /* webpackChunkName: "component---src-pages-grafik-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kompetencer-js": () => import("./../../../src/pages/kompetencer.js" /* webpackChunkName: "component---src-pages-kompetencer-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-webudvikling-js": () => import("./../../../src/pages/webudvikling.js" /* webpackChunkName: "component---src-pages-webudvikling-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */)
}

