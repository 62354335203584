import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const TimelineImage = ({ fileName, alt, style, className }) => {
  const allImageSharp = useStaticQuery(graphql`
    {
      allFile(filter: { sourceInstanceName: { eq: "timeline_images" } }) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(
                width: 231
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
            name
          }
        }
      }
    }
  `)

  const fixed = allImageSharp.allFile.edges.find(
    edge => edge.node.name === fileName
  ).node

  const image = getImage(fixed)

  return (
    <>
      <GatsbyImage
        style={style}
        image={image}
        alt={alt}
        imgClassName={className}
      />
    </>
  )
}

export default TimelineImage
