import React, { useState, useEffect } from "react"
import Footer from "./footer"
import UpdateBanner from "./updatebanner"
import Sidebar from "./sidebar"
import TransitionLink from "gatsby-plugin-transition-link"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/700.css"
import "@fontsource/heebo/900.css"
import { gsap } from "gsap/dist/gsap"
import { GlobalContext } from "../components/soundToggle"

export const ListTLink = props => (
  <TransitionLink
    to={props.to}
    entry={props.entry}
    exit={props.exit}
    trigger={props.trigger}
    aria-label={props.label}
    title={props.label}
    onClick={props.onClick}
  >
    {props.children}
  </TransitionLink>
)

export const transitionFadeIn = () => {
  const tl = gsap.timeline()
  tl.set(".bodyWrapper", { autoAlpha: 0 })
  tl.fromTo(
    ".bodyWrapper",
    { autoAlpha: 0 },
    { duration: 0.2, ease: "none", autoAlpha: 1 },
    0.4
  )
}

export function transitionFadeOut() {
  const tl = gsap.timeline()
  tl.fromTo(
    ".bodyWrapper",
    { autoAlpha: 1 },
    { duration: 0.4, ease: "power4.out", autoAlpha: 0, immediateRender: true }
  )
}

const Layout = ({ children }) => {
  const root =
    typeof window !== `undefined` ? window.document.documentElement : undefined
  const soundEnabled =
    typeof window !== `undefined`
      ? JSON.parse(root.getAttribute("data-sound"))
      : undefined
  const [soundEnabledStatus, setsoundEnabledStatus] = useState(soundEnabled)
  const soundToggle = () =>
    setsoundEnabledStatus(soundEnabledStatus === true ? false : true)
  typeof root !== `undefined`
    ? root.setAttribute("data-sound", soundEnabledStatus)
    : void 0
  typeof localStorage !== `undefined`
    ? localStorage.setItem("SoundEnabled", soundEnabledStatus)
    : void 0
  const [installPromptEvent, setinstallPromptEvent] = useState(undefined)

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", function (e) {
      beforeinstallpromptHandler(e)
    })
  }, [])

  const beforeinstallpromptHandler = beforeInstallPromptEvent => {
    console.info(
      "this is the beforeInstallPromptEvent:",
      beforeInstallPromptEvent
    )
    if (beforeInstallPromptEvent === undefined) {
      return
    }
    setinstallPromptEvent(beforeInstallPromptEvent)
  }

  return (
    <GlobalContext.Provider
      value={{
        soundEnabledStatus,
        soundToggle,
        installPromptEvent,
      }}
    >
      <UpdateBanner />
      <Sidebar />
      {children}
      <Footer />
    </GlobalContext.Provider>
  )
}

export default Layout
