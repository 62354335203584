import React, { useEffect, useContext } from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import UpdateReady from "../sounds/update-ready.mp3"
import { GlobalContext } from "../components/soundToggle.js"

const UpdateBanner = () => {
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const [play2] = useSound(UpdateReady, {
    volume: 0.4,
    soundEnabled: soundEnabled,
  })
  const { t } = useTranslation()

  useEffect(() => {
    const updateReady = window.localStorage.getItem("update-ready")
    if (updateReady === "true") {
      play2()
      document.querySelector(".updateBanner").style.display = "block"
      document.querySelector(".updateBanner").style.opacity = "1"
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="updateBanner"
      style={{
        width: "100vw",
        position: "fixed",
        zIndex: "100",
        top: "0",
        backgroundColor: "var(--flirt-FLIRT_300)",
        display: "none",
        opacity: "0",
        transition: "opacity 1000ms",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ marginLeft: "8px" }}>{t("PWA_UPDATE")}</p>
        <button
          className="updateButton"
          type="submit"
          style={{ fontSize: "14px" }}
          onClick={() => {
            play()
            window.location.reload(true)
            localStorage.setItem("update-ready", "false")
          }}
        >
          {t("UPDATE_PAGE")}
        </button>
      </div>
    </div>
  )
}

export default UpdateBanner
