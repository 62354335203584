import { useState, useEffect } from "react"

export default function useDeviceDetect() {
  const userAgent = typeof window === "undefined" ? "" : navigator.userAgent
  const mobile = Boolean(
    userAgent.match(
      /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    )
  )
  const [isMobile, setMobile] = useState(mobile)

  useEffect(() => {
    const userAgentNow =
      typeof window === "undefined" ? "" : navigator.userAgent
    const mobileNow = Boolean(
      userAgentNow.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    )
    setMobile(mobileNow)
  }, [])
  return { isMobile }
}
