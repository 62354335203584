// extracted by mini-css-extract-plugin
export var chevron = "sidebar-module--chevron--wB2yK";
export var installPWATimeline = "sidebar-module--installPWATimeline--NbKkP";
export var moduleBody = "sidebar-module--moduleBody--RVMBh";
export var moduleBox = "sidebar-module--moduleBox--YpflR";
export var moduleFill = "sidebar-module--moduleFill--0TUIz";
export var moduleHeader = "sidebar-module--moduleHeader--RnU6j";
export var moduleOutline = "sidebar-module--moduleOutline--7SRvh";
export var moduleWrapper = "sidebar-module--moduleWrapper--o6xYD";
export var outline1 = "sidebar-module--outline1--tzjQT";
export var outline2 = "sidebar-module--outline2--WAK2s";
export var outline3 = "sidebar-module--outline3---hD9K";
export var outline4 = "sidebar-module--outline4--MlpI7";
export var outlineGold = "sidebar-module--outlineGold--F7kKL";
export var sideButton = "sidebar-module--sideButton--l9TMJ";
export var sidebar = "sidebar-module--sidebar--Jb6hj";
export var slideRight = "sidebar-module--slide-right--J+Xea";