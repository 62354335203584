import React, { useState, useContext, useEffect } from "react"
import { ListTLink, transitionFadeOut, transitionFadeIn } from "./layout"
import Chevron from "../assets/chevron.inline.svg"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import { InstallPWATimeline } from "../components/installPWA"
import * as styles from "../styles/sidebar.module.css"
import TimelineImage from "./timelineImages"
import { GlobalContext } from "../components/soundToggle"
import useDeviceDetect from "../components/deviceDetect"
import { timelineData } from "../articles/timeline"

const ModuleWrapper = ({ closeSidebar }) => {
  const { isMobile } = useDeviceDetect()
  const { language } = useI18next()

  const skrivTilMig = () => {
    closeSidebar()
    const contactModal =
      typeof window !== `undefined`
        ? document.querySelector(".contactModalOpen")
        : undefined
    contactModal.click()
  }

  const anchor = url => {
    closeSidebar()
    if (typeof window !== `undefined`) window.location.href = url
    //navigate(url)
  }

  const closeTheSidebar = () => {
    closeSidebar()
  }

  const handleEnterKey = e => {
    e.currentTarget.click()
  }

  const keyListenersMap = new Map([[13, handleEnterKey]])
  function keyListener(e) {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)
    // call the listener if it exists
    return listener && listener(e)
  }

  return (
    <div
      className={styles.moduleWrapper}
      style={{ flexDirection: isMobile ? "column-reverse" : "column" }}
    >
      {timelineData.map((data, key) => {
        const url = language === "da" ? data.url_da : data.url_en

        return (
          <section
            className={styles.moduleBox}
            style={{
              marginBottom: isMobile ? "0" : "32px",
              marginTop: isMobile ? "32px" : "0",
            }}
          >
            {data.outline ? (
              <div
                className={styles.moduleOutline + " " + styles[data.outline]}
              />
            ) : (
              <div className={styles.moduleOutline} />
            )}
            <div className={styles.moduleFill} />
            <div className={styles.moduleHeader}>
              {!!data.special && (
                <button
                  onKeyPress={e => keyListener(e)}
                  onClick={
                    data.special === "skrivTilMig"
                      ? skrivTilMig
                      : language === "da"
                      ? () => anchor(data.url_da)
                      : () => anchor(data.url_en)
                  }
                  style={{
                    cursor: "pointer",
                    zIndex: "5",
                    border: "unset",
                    padding: "0",
                    backgroundColor: "unset",
                  }}
                >
                  {language === "da" ? (
                    <TimelineImage
                      fileName={data.image}
                      alt={data.image_alt_da}
                      className={!!data?.image_class && data?.image_class}
                    />
                  ) : (
                    <TimelineImage
                      fileName={data.image}
                      alt={data.image_alt_en}
                      className={!!data?.image_class && data?.image_class}
                    />
                  )}
                </button>
              )}
              {data.special === undefined && (
                <ListTLink
                  onClick={closeTheSidebar}
                  to={url}
                  exit={{
                    length: 0.4,
                    trigger: ({ exit, e, node }) =>
                      transitionFadeOut(exit, node),
                  }}
                  entry={{
                    delay: 0.4,
                    appearAfter: 0.4,
                    length: 0.4,
                    trigger: ({ entry, node }) => transitionFadeIn(node),
                  }}
                >
                  {language === "da" ? (
                    <TimelineImage
                      fileName={data.image}
                      alt={data.image_alt_da}
                    />
                  ) : (
                    <TimelineImage
                      fileName={data.image}
                      alt={data.image_alt_en}
                    />
                  )}
                </ListTLink>
              )}
            </div>
            <div className={styles.moduleBody}>
              {!!data.special && (
                <button
                  className="noHrefLink"
                  tabIndex="0"
                  onKeyPress={e => keyListener(e)}
                  onClick={
                    data.special === "skrivTilMig"
                      ? skrivTilMig
                      : language === "da"
                      ? () => anchor(data.url_da)
                      : () => anchor(data.url_en)
                  }
                  style={{ cursor: "pointer" }}
                >
                  <h3>
                    {language === "da" ? data.headline_da : data.headline_en}
                  </h3>
                </button>
              )}
              {data.special === undefined && (
                <ListTLink
                  onClick={closeTheSidebar}
                  to={url}
                  exit={{
                    length: 0.4,
                    trigger: ({ exit, e, node }) =>
                      transitionFadeOut(exit, node),
                  }}
                  entry={{
                    delay: 0.4,
                    appearAfter: 0.4,
                    length: 0.4,
                    trigger: ({ entry, node }) => transitionFadeIn(node),
                  }}
                >
                  {language === "da" ? (
                    <h3>{data.headline_da}</h3>
                  ) : (
                    <h3>{data.headline_en}</h3>
                  )}
                </ListTLink>
              )}
              {language === "da" ? (
                <p>{data.body_da}</p>
              ) : (
                <p>{data.body_en}</p>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                {language === "da" ? (
                  <p
                    style={{
                      padding: "0 10px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                      color: "var(--verdigris-VERDIGRIS_500)",
                    }}
                  >
                    {data.date_da}
                  </p>
                ) : (
                  <p
                    style={{
                      padding: "0 10px",
                      fontStyle: "italic",
                      fontWeight: "bold",
                      color: "var(--verdigris-VERDIGRIS_600)",
                    }}
                  >
                    {data.date_en}
                  </p>
                )}
              </div>
            </div>
          </section>
        )
      })}
    </div>
  )
}

const Sidebar = () => {
  const { isMobile } = useDeviceDetect()
  const { t } = useTranslation()
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })

  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (window.innerWidth > 2032) {
      setExpanded(true)
    } else {
      document.querySelector('[class*="sidebar"]').style.display = "none"
    }
  }, [])

  const toggleOpen = () => {
    play()
    setExpanded(!expanded)
    expanded
      ? setTimeout(function () {
          document.querySelector('[class*="sidebar"]').style.display = "none"
        }, 400)
      : (document.querySelector('[class*="sidebar"]').style.display = "block")
  }

  const handleEnterKey = () => {
    play()
    setExpanded(!expanded)
  }

  const keyListenersMap = new Map([[13, handleEnterKey]])
  function keyListener(e) {
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)
    // call the listener if it exists
    return listener
  }

  const closeSidebar = () => {
    setExpanded(false)
    setTimeout(function () {
      document.querySelector('[class*="sidebar"]').style.display = "none"
    }, 400)
  }

  return (
    <>
      <aside
        aria-hidden={!expanded}
        aria-label="timeline"
        className={styles.sidebar + " " + expanded}
        style={{
          overflow: "scroll",
          flexDirection: isMobile ? "column-reverse" : "column",
          transform: expanded ? "translateX(284px)" : "translateX(-5px)",
        }}
      >
        {isMobile && (
          <section>
            <InstallPWATimeline />
          </section>
        )}
        <ModuleWrapper closeSidebar={closeSidebar} />
      </aside>
      <button
        onKeyPress={e => keyListener(e)}
        onClick={toggleOpen}
        style={{
          transform: expanded ? "translateX(284px)" : "translateX(-5px)",
        }}
        className={styles.sideButton}
        aria-label={
          expanded ? t("SIDEBAR_BUTTON_CLOSE") : t("SIDEBAR_BUTTON_OPEN")
        }
        title={expanded ? t("SIDEBAR_BUTTON_CLOSE") : t("SIDEBAR_BUTTON_OPEN")}
      >
        <Chevron
          style={{
            left: expanded ? "8px" : "13px",
            transform: expanded ? "Rotate(180deg)" : "none",
          }}
          className={styles.chevron}
        />
      </button>
    </>
  )
}

export default Sidebar
