import "./src/styles/global.css"

export const onInitialClientRender = () => {
  document.getElementById("___loader").style.display = "none"
}

export const onRouteUpdate = () => {
  navigator.serviceWorker.register("/sw.js").then(reg => {
    reg.update()
  })
}

// gatsby-browser.js
export const onServiceWorkerUpdateReady = () => {
  localStorage.setItem("update-ready", "true")
  document.querySelector(".updateBanner").style.display = "block"
  document.querySelector(".updateBanner").style.opacity = "1"
}
