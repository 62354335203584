import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  createRef,
} from "react"
import ReactDOM from "react-dom"
import { navigate } from "gatsby"
import Envelope from "../assets/envelope-open-text.inline.svg"
import Cross from "../assets/cross.inline.svg"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import useSound from "use-sound"
import ButtonClick from "../sounds/button-click.mp3"
import ToggleSound from "../sounds/toggle-button.mp3"
import { GlobalContext } from "../components/soundToggle"
import { OnlineHook } from "../components/networkStatus"

function encode(formData) {
  return Object.keys(formData)
    .map(
      key => encodeURIComponent(key) + "=" + encodeURIComponent(formData[key])
    )
    .join("&")
}

const ContactForm = () => {
  const isOnline = OnlineHook()
  const { language } = useI18next()
  const root =
    typeof window !== `undefined` ? window.document.documentElement : undefined
  const pageLanguage =
    typeof window !== `undefined` ? root.getAttribute("lang") : undefined
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play] = useSound(ButtonClick, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const [play2] = useSound(ToggleSound, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const { t } = useTranslation()

  let confirmPage
  if (language === "da") {
    confirmPage = "/besked-modtaget/"
  } else {
    confirmPage = `/${language}/besked-modtaget/`
  }

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [state, setState] = useState({})
  useEffect(() => {
    if (document.querySelector(".modal-body") !== null) {
      setTimeout(function () {
        document.querySelector(".modal-body #modal-focus").focus()
      }, 400)
    }
  }, [isModalVisible])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleBlur = e => {
    if (e.currentTarget.hasAttribute("required")) {
      return
    } else {
      e.currentTarget.optional = false
      e.currentTarget.required = true
    }
  }

  useEffect(() => {
    setState({ ...state, language: pageLanguage })
  }, [pageLanguage]) // eslint-disable-line react-hooks/exhaustive-deps

  const closeModal = e => {
    play2()
    document.querySelector(".modal-container").classList.add("fadeOut")
    document.querySelector(".modal-content").classList.add("fadeOut")
    setTimeout(function () {
      setIsModalVisible(false)
    }, 400)
  }

  const postUrl = "/?t=" + Math.floor(Date.now() / 1000)

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch(postUrl, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")), closeModal())
      .catch(error => alert(error))
  }

  return (
    <>
      <button
        tabIndex="-1"
        className="contactModalOpen"
        title={t("CONTACT_BUTTON_TITLE")}
        onClick={() => setIsModalVisible(true)}
      >
        <Envelope
          onClick={play2}
          tabindex="0"
          role="button"
          aria-label="Write to me"
          className="envelope"
          height="24"
        />
      </button>
      {isModalVisible && (
        <Modal onModalClose={() => closeModal()}>
          <Modal.Header>
            <Envelope
              className="envelopeInForm"
              height="32"
              style={{ margin: "0 8px 0", filter: "none" }}
            />
            <h2 style={{ margin: "0" }}>{t("CONTACT_FORM.TITLE")}</h2>
          </Modal.Header>
          <Modal.Body>
            <form
              name="skrivTilMig"
              method="post"
              action={confirmPage}
              data-netlify="true"
              onSubmit={handleSubmit}
              autocomplete="off"
            >
              <input
                type="hidden"
                autocomplete="prevent-autocomplete-in-chrome"
                name="form-name"
                value="skrivTilMig"
              />
              <noscript>
                <p>{t("CONTACT_FORM.NO_JAVASCRIPT")}</p>
              </noscript>
              <p className="mediumLabel" style={{ marginTop: "16px" }}>
                <label>
                  {t("CONTACT_FORM.NAME")}
                  <br />
                  <input
                    autocomplete="address-level4"
                    onBlur={handleBlur}
                    placeholder={t("CONTACT_FORM.NAME_PLACEHOLDER")}
                    type="text"
                    name="name"
                    id="modal-focus"
                    optional
                    onChange={handleChange}
                  />
                  <span></span>
                </label>
              </p>
              <p className="mediumLabel" style={{ marginTop: "16px" }}>
                <label>
                  {t("CONTACT_FORM.EMAIL")}
                  <br />
                  <input
                    pattern="[a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+"
                    autocomplete="off"
                    onBlur={handleBlur}
                    type="email"
                    placeholder={t("CONTACT_FORM.EMAIL_PLACEHOLDER")}
                    name="email"
                    optional
                    onChange={handleChange}
                  />
                  <span></span>
                </label>
              </p>
              <p className="mediumLabel" style={{ marginTop: "16px" }}>
                <label>
                  {t("CONTACT_FORM.SUBJECT")}
                  <br />
                  <input
                    autocomplete="off"
                    onBlur={handleBlur}
                    type="text"
                    placeholder={t("CONTACT_FORM.SUBJECT_PLACEHOLDER")}
                    name="subject"
                    optional
                    onChange={handleChange}
                  />
                  <span></span>
                </label>
              </p>
              <p className="mediumLabel" style={{ marginTop: "16px" }}>
                <label>
                  {t("CONTACT_FORM.MESSAGE")}
                  <br />
                  <textarea
                    autocomplete="off"
                    onBlur={handleBlur}
                    name="message"
                    placeholder={t("CONTACT_FORM.MESSAGE_PLACEHOLDER")}
                    optional
                    onChange={handleChange}
                    style={{ height: "100px" }}
                  />
                  <span></span>
                </label>
              </p>
              <p className="mediumLabel" style={{ display: "none" }}>
                <label>
                  Language
                  <br />
                  <input
                    type="text"
                    name="language"
                    required
                    style={{ display: "none" }}
                    defaultValue={pageLanguage}
                    readonly
                  ></input>
                  <span></span>
                </label>
              </p>
              {isOnline ? (
                <p>
                  <button
                    id="sendEmail"
                    onClick={play}
                    title={t("CONTACT_FORM.SEND_BUTTON_TITLE")}
                    type="submit"
                  >
                    {t("CONTACT_FORM.BUTTON")}
                  </button>
                </p>
              ) : (
                <h3
                  style={{
                    minHeight: "44px",
                    fontSize: "20px",
                    color: "var(--flirt-FLIRT_500)",
                    textAlign: "center",
                    marginBlockStart: "16px",
                    marginBlockEnd: "16px",
                  }}
                >
                  {t("NO_INTERNET_CONNECTION")}
                </h3>
              )}
            </form>
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

const modalContext = createContext()

function Modal({ children, onModalClose }) {
  useEffect(() => {
    function keyListener(e) {
      if (e.keyCode === 27) {
        onModalClose()
      }
    }

    document.addEventListener("keydown", keyListener)

    return () => document.removeEventListener("keydown", keyListener)
  })

  const modalRef = createRef()
  const handleTabKey = e => {
    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"],input[type="email"], input[type="checkbox"], select'
    )
    const firstElement = focusableModalElements[0]
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1]

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  const keyListenersMap = new Map([
    [27, onModalClose],
    [9, handleTabKey],
  ])

  function keyListener(e) {
    if (modalRef.current === null) return
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)

    // call the listener if it exists
    return listener && listener(e)
  }

  document.addEventListener("keydown", keyListener)

  return ReactDOM.createPortal(
    <div className="modal-container" role="dialog" aria-modal="true">
      <div className="modal-content" ref={modalRef}>
        <modalContext.Provider value={{ onModalClose }}>
          {children}
        </modalContext.Provider>
      </div>
    </div>,
    document.body
  )
}

Modal.Header = function ModalHeader(props) {
  const { onModalClose } = useContext(modalContext)
  const { t } = useTranslation()
  return (
    <div className="modal-header">
      {props.children}
      <button
        className="cross-btn"
        title={t("CONTACT_FORM.CLOSE_BUTTON_TITLE")}
        onClick={onModalClose}
      >
        <Cross style={{ filter: "none" }} className="cross" width="22" />
      </button>
    </div>
  )
}

Modal.Body = function ModalBody(props) {
  return <div className="modal-body">{props.children}</div>
}

export default ContactForm
