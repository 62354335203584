import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  createRef,
} from "react"
import ReactDOM from "react-dom"
import ShareIcon from "../assets/share-alt.inline.svg"
import Cross from "../assets/cross.inline.svg"
import { useTranslation } from "gatsby-plugin-react-i18next"
import ShareButtons from "../components/share"
import useSound from "use-sound"
import ToggleSound from "../sounds/toggle-button.mp3"
import { GlobalContext } from "../components/soundToggle"

const ShareModal = () => {
  const useSoundEnabled = useContext(GlobalContext)
  const soundEnabled = useSoundEnabled.soundEnabledStatus
  const [play2] = useSound(ToggleSound, {
    volume: 0.25,
    soundEnabled: soundEnabled,
  })
  const { t } = useTranslation()
  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    if (document.querySelector(".modal-body") !== null) {
      setTimeout(function () {
        document.querySelector(".modal-header > .cross-btn-share").focus()
      }, 400)
    }
  }, [isModalVisible])

  const closeModal = e => {
    play2()
    document.querySelector(".modal-container").classList.add("fadeOut")
    document.querySelector(".modal-content").classList.add("fadeOut")
    setTimeout(function () {
      setIsModalVisible(false)
    }, 400)
  }

  return (
    <>
      <button
        tabIndex="-1"
        className="shareOpen"
        title={t("SHARE.BUTTON_TITLE")}
        onClick={() => setIsModalVisible(true)}
      >
        <ShareIcon
          onClick={play2}
          tabindex="0"
          role="button"
          aria-label="Share this page"
          className="share"
          height="24"
        />
      </button>
      {isModalVisible && (
        <Modal onModalClose={() => closeModal()}>
          <Modal.Header>
            <ShareIcon
              className="shareIcon"
              height="24"
              style={{ marginRight: "8px", filter: "none" }}
            />
            <h2 style={{ lineHeight: "26px", margin: "0" }}>
              {t("SHARE.MODAL_TITLE")}
            </h2>
          </Modal.Header>
          <Modal.Body>
            <ShareButtons />
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}

const modalContext = createContext()

function Modal({ children, onModalClose }) {
  useEffect(() => {
    function keyListener(e) {
      if (e.keyCode === 27) {
        onModalClose()
      }
    }

    document.addEventListener("keydown", keyListener)

    return () => document.removeEventListener("keydown", keyListener)
  })

  const modalRef = createRef()
  const modalContainerRef = createRef()
  const handleTabKey = e => {
    let focusableModalElements = []
    const allFocusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"],input[type="email"], input[type="checkbox"], select'
    )
    allFocusableModalElements.forEach(element => {
      if (window.getComputedStyle(element).display !== "none") {
        focusableModalElements.push(element)
      }
    })
    const firstElement = focusableModalElements[0]
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1]
    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }
  }

  const keyListenersMap = new Map([
    [27, onModalClose],
    [9, handleTabKey],
  ])

  function keyListener(e) {
    if (modalRef.current === null) return
    // get the listener corresponding to the pressed key
    const listener = keyListenersMap.get(e.keyCode)

    // call the listener if it exists
    return listener && listener(e)
  }

  document.addEventListener("keydown", keyListener)

  return ReactDOM.createPortal(
    <div
      className="modal-container"
      role="dialog"
      aria-modal="true"
      ref={modalContainerRef}
    >
      <div className="modal-content" ref={modalRef}>
        <modalContext.Provider value={{ onModalClose }}>
          {children}
        </modalContext.Provider>
      </div>
    </div>,
    document.body
  )
}

Modal.Header = function ModalHeader(props) {
  const { onModalClose } = useContext(modalContext)
  const { t } = useTranslation()
  return (
    <div className="modal-header">
      {props.children}
      <button
        className="cross-btn-share"
        title={t("SHARE.CLOSE_BUTTON_TITLE")}
        onClick={onModalClose}
      >
        <Cross style={{ filter: "none" }} className="cross" width="22" />
      </button>
    </div>
  )
}

Modal.Body = function ModalBody(props) {
  return <div className="modal-body">{props.children}</div>
}

export default ShareModal
